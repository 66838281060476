exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-additional-services-js": () => import("./../../../src/pages/additional-services.js" /* webpackChunkName: "component---src-pages-additional-services-js" */),
  "component---src-pages-canadian-disability-rebate-form-js": () => import("./../../../src/pages/canadian-disability-rebate-form.js" /* webpackChunkName: "component---src-pages-canadian-disability-rebate-form-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-disability-refund-process-js": () => import("./../../../src/pages/disability-refund-process.js" /* webpackChunkName: "component---src-pages-disability-refund-process-js" */),
  "component---src-pages-disability-tax-credit-js": () => import("./../../../src/pages/disability-tax-credit.js" /* webpackChunkName: "component---src-pages-disability-tax-credit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-types-of-disabilities-js": () => import("./../../../src/pages/types-of-disabilities.js" /* webpackChunkName: "component---src-pages-types-of-disabilities-js" */),
  "component---src-templates-city-template-js": () => import("./../../../src/templates/city-template.js" /* webpackChunkName: "component---src-templates-city-template-js" */),
  "component---src-templates-services-template-js": () => import("./../../../src/templates/services-template.js" /* webpackChunkName: "component---src-templates-services-template-js" */)
}

